
































import { StyleCheck } from "@/mixins/style-check";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Notification } from "@/models/notification.interface";

@Component({})
export default class IconsPicker extends mixins(StyleCheck) {
  @Prop(String) label!: string;
  @Prop({}) rules!: any;
  @Prop(String) current!: string;
  @Prop(Boolean) clearPicker!: boolean;

  @Watch("current")
  private updateCurrentIcon(val) {
    this.icon = val;
  }

  @Watch("clearPicker")
  private handleClear(val) {
    if (val) {
      this.icon = "";
      this.loading = false;
    }
  }

  icon = "";
  loading = false;

  private sendIcon(value: any) {
    if (value !== null) {
      this.icon = value.name;
      this.$emit("getIcon", value);
    }
  }

  private async created() {
    this.loading = true;
    if (this.icons.length == 0) {
      await this.$store.dispatch("icons/getIcons").catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.ip-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
    }

    if (this.current !== "") {
      this.icon = this.current;
    }
    this.loading = false;
  }

  private get icons() {
    return this.$store.getters["icons/getIcons"];
  }
}
